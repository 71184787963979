<template>
	<div class="detailcon">
		<div class="line1">
			<div class="title">{{ detail.title }}</div>
			<div class="downbtn" v-if="detail.deal_file_url" v-down="detail.deal_file_url" >
				<img src="@/assets/images/cjxz.png" alt="">
				<span>成交文档下载</span>
			</div>
		</div>
		<div class="line2">
			<div v-if="detail.source" class="laiyuan item">
				<div>来源：</div>
				<div>{{ detail.source }}</div>
			</div>
			<div v-if="detail.edit" class="zhengli item">
				<div>编辑整理：</div>
				<div>{{ detail.edit }}</div>
			</div>
			<div v-if="detail.author" class="zuozhe item">
				<div>作者：</div>
				<div>{{ detail.author }}</div>
			</div>
			<div v-if="detail.created_at" class="fabushijian item">
				<div>发布时间：</div>
				<div>{{ detail.created_at.substr(0, 10) }}</div>
			</div>
		</div>
		<div class="line3" v-if="detail.summary">
			<span class="tags">摘要</span>
			{{ detail.summary }}
		</div>
		<div class="content">
			<p v-html="detail.content"></p>
		</div>
		<div class="shengming">
			免责声明：本站旨在为广大用户提供更多信息服务，本文仅代表作者本人观点，和土拍网无关。本文不做商业用途，如有侵权，请及时联系电话0512-65980160，我们将立即删除！
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProjectDetail',
	props: {
		detail: {
			type: Object
		}
	},

	mounted() {

	},

	methods: {



	
	},
};
</script>

<style lang="scss" scoped>
.shengming{
	width: 100%;
    font-size: 14px;
    color: #AAAAAA;
    background-color: #fafafa;
    padding: 10px 0 10px 20px;
    margin-top: 10px;
    box-sizing: border-box;
		text-align: left;
		margin-bottom: 20px;
}

.line1 {
	width: 100%;
	display: flex;
	margin-top: 20px;
	align-items: center;

	.title {
		min-height: 37px;
		width: 100%;
		margin-left:0px;
		min-height: 37px;
		font-size: 26px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 37px;
		white-space: wrap; //不折行
	}
}

.downbtn {
	cursor: pointer;
	margin-left: 10px;
	width: 112px;
	height: 28px;
	background: #E77817;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 22px;

	img {
		margin-right: 5px;
		width: 12px;
		height: 12px;
	}
}

.line2 {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 10px;

	.item {
		height: 16px;
		display: flex;
		padding: 0 10px;
		display: flex;
		align-items: center;
		border-right: solid 1px #A0A0A0;
		div{
			line-height: 16px;
			height: 16px;
		}
	}

	.item:last-child {
		border-right: none;
	}

}

.line3 {
	width: 100%;
	line-height: 48px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	white-space: break-spaces;
	word-wrap: wrap;
	border-top: dashed 1px #ABB0B1;
	border-bottom: dashed 1px #ABB0B1;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: left;

	.tags {
		width: 46px;
		height: 24px;
		color: #00A3E4;
		border: solid 1px #00A3E4;
		border-radius: 4px;
		padding: 2px 6px;

	}
}

.content {
	margin: 0;
	margin-top: 20px;
	width: 100%;
	height: auto;
	text-align: left;
	font-size: 12pt;
}



</style>